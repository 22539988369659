@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600;700&display=swap");

:root {
  --primary-color: #cd0000;
  --secondary-color: #030290;
  --footer-color: #4d4d4d;
  --white-color: #ffffff;
  --feature-back: #4f4eb1;
  --book-button: #1c1b9b;
  --gray-color: #949090;
  --font-family: "Lato", sans-serif;
}

.section {
  margin-top: 50px;
  margin-bottom: 50px;
}

.all-section {
  margin-top: 80px;
  margin-bottom: 80px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: var(--font-family);
  text-align: justify;
}

h3 {
  font-size: 25px;
  font-weight: 400;
  color: var(--primary-color);
  text-align: center;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
  color: var(--primary-color);
}

/*------ solid button ------ */

.fom-btn {
  color: var(--white-color);
  background: var(--primary-color);
  border: none;
  padding: 5px 20px;
  margin: 8px 0 1px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.fom-btn:hover {
  color: var(--white-color);
  background: #c00101;
}

.fom-btn:active {
  color: var(--white-color) !important;
  background: #c00101 !important;
  border-color: var(--primary-color) !important;
}

/* --------------outline-button------------- */

.out-lin-btn {
  color: var(--primary-color);
  background: var(--white-color);
  border: 1.5px solid var(--primary-color);
  padding: 5px 20px;
}

.out-lin-btn:hover {
  color: var(--white-color);
  background: var(--primary-color);
  transition: 0.5s ease;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.out-lin-btn:active {
  color: var(--primary-color) !important;
  background: var(--white-color) !important;
  border-color: var(--primary-color) !important;
  border: 1.5px solid var(--primary-color);
}

/* -----------Navbar----------- */

.navbar-brand:hover,
.brand-text:hover {
  cursor: pointer;
}

.navbar-brand {
  height: 68px;
}

.navbar-brand img {
  width: 100%;
  height: 100%;
}

.navbar-brand img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.navbar-donate {
  border: 1px solid var(--primary-color);
}

/* -----------Navbar----------- */

/* ---------------About------------ */
.about-img {
  height: 500px;
}

.about-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.text {
  font-family: "Lato", sans-serif;
}
.about-img {
  height: 500px;
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .about-img {
    height: 350px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .about-img {
    height: 285px !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .about-img {
    height: 185px !important;
  }
}

@media only screen and (min-width: 200px) and (max-width: 480px) {
  .about-img {
    height: 125px !important;
  }
}

.about-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.about-img2 {
  height: 550px;
}

.about-img2 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* ---------------About------------ */

/* ---------------booking------------ */
.bookingImg {
  height: 384px;
}

.bookingImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* ---------------booking------------ */

.text {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
}

.text-1 {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: justify;
}

/* ---------------FAQ's------------ */
.faq-img {
  width: 600px;
  height: 224px;
}

.faq-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* ---------------FAQ's------------ */

/* ---------------Blog------------ */

.blog-link {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 16px;
}

/* ---------------Blog------------ */

.brand-text span {
  color: var(--secondary-color);
  font-family: var(--font-family);
  font-size: 16px;
}

.nav-lst-itm ul li a {
  color: var(--primary-color);
  font-family: var(--font-family);
  font-size: 16px;
  margin-right: 1rem;
  margin-left: 1rem;
  transition: 0.5s ease;
}

.nav-lst-itm ul li a:hover {
  color: var(--primary-color);
}

.nav-lst-itm ul li button {
  color: var(--primary-color);
  font-family: var(--font-family);
  font-size: 16px;
}

.nav-lst-itm ul li button:hover {
  background: var(--primary-color);
  color: var(--white-color);
}

/*------------ Banner---------- */

.item {
  height: 600px;
}

.item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.owl-carousel .owl-prev {
  width: 35px !important;
  height: 35px !important;
  border-radius: 25px !important;
  background: var(--secondary-color) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--gray-color) !important;
  position: absolute !important;
  left: 30px !important;
  top: 45% !important;
}

.owl-carousel .owl-prev span {
  font-size: 40px !important;
  margin-top: -10px !important;
}

.owl-carousel .owl-next {
  width: 35px !important;
  height: 35px !important;
  border-radius: 25px !important;
  background: var(--secondary-color) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--gray-color) !important;
  position: absolute !important;
  right: 30px !important;
  top: 45% !important;
}

.owl-carousel .owl-next span {
  font-size: 40px !important;
  margin-top: -10px !important;
}

.owl-theme .owl-dots {
  position: relative !important;
  margin-top: -3rem !important;
}

.owl-theme .owl-dots .owl-dot span {
  background: rgb(190, 188, 188) !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: var(--secondary-color) !important;
}

/* -----------booking form-------------- */

.bnr-fom-sec {
  padding: 1rem 1.5rem;
  background: rgba(255, 255, 255, 0.8);
  position: relative;
  z-index: 2;
  top: -35rem;
  width: 30%;
  border-radius: 20px;
  margin: 0 6rem;
  margin-bottom: -33rem !important;
}

.online-bok-fom select {
  border: 1px solid var(--primary-color) !important;
  background-color: rgba(228, 225, 225, 0.7) !important;
  color: var(--primary-color) !important;
  font-size: 16px !important;
  font-family: var(--font-family);
}

.online-bok-fom select:focus {
  box-shadow: none !important;
}

.online-bok-fom input {
  border: 1px solid var(--primary-color) !important;
  background-color: rgba(228, 225, 225, 0.8) !important;
}

.online-bok-fom input:focus {
  box-shadow: none !important;
}

.online-bok-fom input::placeholder {
  color: var(--primary-color) !important;
  font-size: 16px !important;
  font-family: var(--font-family);
}

.online-bok-fom textarea {
  border: 1px solid var(--primary-color) !important;
  background-color: rgba(228, 225, 225, 0.8) !important;
}

.online-bok-fom textarea:focus {
  box-shadow: none !important;
}

.online-bok-fom textarea::placeholder {
  color: var(--primary-color) !important;
  font-size: 16px !important;
  font-family: var(--font-family);
}

.accordion {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

@media only screen and (max-width: 1025px) {
  .item {
    height: 100% !important;
  }

  .bnr-fom-sec {
    top: 1rem !important;
    margin-bottom: 0 !important;
    width: 100% !important;
    padding: 0 3rem !important;
    margin: 3.5rem 0 !important;
  }

  .online-bok-fom select {
    background-color: transparent !important;
  }

  .online-bok-fom input {
    background-color: transparent !important;
  }
}

/*-------------------- gallery------------------ */

.albumn-card:hover {
  cursor: pointer;
}

.gallery-imgs {
  height: 310px;
}

.gallery-imgs img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* ----------------Blogs---------------- */

.blog-crd-sec {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: none !important;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  height: 300px;
}

.blog-crd-sec img {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.blog-crd-sec:hover {
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

/* Feature */

.feature-sec {
  background: var(--feature-back);
  padding: 3rem 0;
}

.fetr-icm-txt i {
  color: var(--white-color);
  font-size: 2.2rem;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-bottom: 1rem;
}

.fetr-icm-txt p {
  color: var(--white-color);
  font-size: 22px;
  text-align: center;
}

/* ------------type of message----------------- */
.typ-of-mass {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  border: none !important;
  border-radius: 1.5rem !important;
}

.mass-typ-img {
  padding: 1rem;
  background: #d9d9d9;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  height: 15rem;
}

.mass-typ-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.mess-typ-bdy {
  background: var(--primary-color);
  color: var(--white-color);
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.chair-man-img {
  height: 550px;
}

.chair-man-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.txt-sec {
  font-size: 16px;
  text-align: justify;
  color: var(--gray-color);
}

.brch-sec {
  border: none;
  border-radius: 1.5rem;
}

.top-txt {
  background: var(--primary-color);
  color: var(--white-color);
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  padding: 10px 30px 0;
}

.brch-para-txt ul {
  padding-left: 0 !important;
}

.brch-para-txt ul li {
  color: var(--feature-back);
  font-size: 14px;
  list-style: none;
}

.brch-para-txt ul li a {
  color: var(--feature-back);
}

.foot-sec-dv {
  background: var(--footer-color);
  padding: 1.5rem 2rem;
}

.foot-logo {
  height: 200px;
}

.foot-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.foot-txt {
  font-size: 14px;
  text-align: justify;
  color: var(--white-color);
  line-height: 1.2rem;
}

.foot-list ul li {
  list-style-type: none;
}

.foot-list ul li i {
  color: white;
}

.foot-list ul li a {
  text-decoration: none;
  color: var(--white-color);
  line-height: 2.5rem;
}

.foot-social ul li {
  display: inline;
  margin-right: 10px;
}

.contact {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 17px;
  line-height: 19px;
  color: var(--secondary-color);
}

.contact p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  color: var(--primary-color);
}

.contact-email {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  color: var(--primary-color);
  margin-top: 2rem;
}

.contact-image {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.contact-image h5 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--footer-color);
  text-align: center;
}

.contact-image a {
  text-decoration: none;
}

.contact-text p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: var(--footer-color);
}

.blog-Description h1 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: var(--footer-color);
}
.blog-Description-text p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  text-align: justify;
  color: var(--gray-color);
}

.caption {
  text-align: center;
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.Lists li {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 15px;
  color: var(--footer-color);
}

.studio-img {
  height: 400px;
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .studio-img {
    height: 350px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .studio-img {
    height: 285px !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .studio-img {
    height: 185px !important;
  }
}

@media only screen and (min-width: 200px) and (max-width: 480px) {
  .studio-img {
    height: 125px !important;
  }
}
.studio-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.studio-img2 {
  height: 650px;
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .studio-img2 {
    height: 350px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .studio-img2 {
    height: 285px !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .studio-img2 {
    height: 185px !important;
  }
}

@media only screen and (min-width: 200px) and (max-width: 480px) {
  .studio-img2 {
    height: 125px !important;
  }
}
.studio-img2 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 810px) {
  .divider-img {
    width: 20rem !important;
  }
  .fetr-icm-txt p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 990px) {
  .typ-of-mass {
    margin-bottom: 2rem !important;
  }
}
.card-team-card {
  width: 100%;
  background-color: lightgray;
  position: relative;
  margin-top: 30%;
}

.team-image {
  width: 175px;
  height: 225px;
  border: 1px solid red;
  background-color: #ffffff;
  padding: 8px;
  position: absolute;
  transform: translateX(50%);
  right: 50%;
  top: -30%;
}

.team-image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.bottom {
  margin-bottom: 2rem;
}

.card-body-team {
  margin-top: 20%;
}

@media only screen and (min-width: 990px) and (max-width: 1200px) {
  .team-image {
    top: -20% !important;
    width: 230px !important;
  }
}

@media only screen and (min-width: 765px) and (max-width: 990px) {
  .team-image {
    top: -20% !important;
    width: 230px !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .card-body-team {
    margin-top: 20% !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .card-team-card {
    margin-top: 25% !important;
  }
}

@media only screen and (max-width: 430px) {
  .bottom {
    margin-bottom: 5rem !important;
  }
}

@media only screen and (max-width: 430px) {
  .team-image {
    width: 220px;
    top: -20%;
  }
}
@media only screen and (max-width: 430px) {
  .card-team-card {
    margin-top: 20%;
  }
}
.card-team-card2 {
  width: 100%;
  background-color: lightgray;
  position: relative;
  margin-top: 15%;
}

.team-image2 {
  width: 50%;
  height: 450px;
  padding: 8px;
}

/* .card-body-team2{
  margin-top: 18%;
} */

.modal-width {
  max-width: 700px !important;
}
